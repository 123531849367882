<template>
  <div class="changeNetwork">
    
    <div class="cardList" v-for="(item,index) in list" @click="onInput(item.solt,item.ispick,item.chtype,item.iccid)">
        <div class="card_left">
            <!-- <img :src= item.solt == '0'?"../../assets/images/changeNetwork/Master.png":"../../assets/images/changeNetwork/Secondary.png" alt=""> -->
            <img v-if="item.solt == '0'" src="../../assets/images/changeNetwork/Master.png" alt="">
            <img v-else src="../../assets/images/changeNetwork/Secondary.png" alt="">
            <div class="card_info">
                <div class="card_name">{{item.chtype == 1?'中国移动':item.chtype == 2?'中国联通':'中国电信'}}</div>
                <div :class="[item.ispick == 2?'hadcard_state':'card_state']">{{item.ispick == 2?'已实名':'未实名'}}</div>
            </div>
        </div>
        <div class="card_right">
            <van-switch :value="checked"   size="18px" inactive-color ="#E2E7EE" active-color="#207EF4" :active-value="item.solt - 0" />
            <!-- <button class="goName">去实名</button> -->
        </div>
    </div>
    <div class="intelligent">
        <div class="intelligent_info" @click="onInput(checked=50)">
            <div class="intelligent_left">
                <div class="intelligent_title">智能选网</div>
                <div class="intelligent_tip">根据网络质量，智能切换网络</div>
            </div>
            <van-switch :value="checked"  :active-value='50'size="18px" inactive-color ="#E2E7EE" active-color="#207EF4" />
        </div>
        <div class="hint">
            温馨提示：切换网络前，请确保该卡槽已进行官方实名，官方实名成功后方可启用。
        </div>
    </div>
    <van-overlay :show="isloading" @click="show = false">
  <div class="wrapper" @click.stop>
    <div class="block">
        <van-loading color="#1989fa" size="30px" /><span class="waite">{{loadingText}}</span>
    </div>
    
  </div>
</van-overlay>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import {get,post} from "../../untils/request"
import Axios from "axios"
export default {
    data(){
       return{
            checked: 40,//开关
            list:[],//卡列表信息
            switch_mode:'',//0：自动模式， 1：手动模式
            sim_id:'',//0:卡一、1:卡二、 2:代表USIM 目前2参数业务没涉及到，代码只有切换0和1
            iccid:'',
            isloading:false,
            loadingText:'正在连接您的随身WiFi，请稍候...',
            dev_type:'',//设备型号 目前有xy 和 nx 两种型号， 型号不同，切网请求的接口不同
        }
    },
    mounted(){
        let geturl = window.location.href 
        let getqyinfo = geturl.split('?')[1]
        let getqys = new URLSearchParams('?'+getqyinfo)
        this.iccid = getqys.get('dn')
        this.getListInfo()
        

    },
    methods: {
        //获取卡列表信息
        getListInfo(){
            let data ={
                iccid:this.iccid
            }
            this.getNetList({data}).then(res =>{
                this.list = res.data.list
                this.phone = res.data.info.phone
                this.dev_type = res.data.info.dev_type
                // 判断是否有一张实名的
                let isPackEqualsTwo = this.list.some(item => item.ispick == 2);
                if(isPackEqualsTwo){
                    if(this.dev_type == 'nx'){
                        this.imeinetfn()
                    }else{
                        this.imeinetfn_xy()
                    }
                }else{
                    this.$toast('您还未实名，请先-实名')
                }
                
                 
                
            })
        },
        //开关切换
        onInput(checked,ispick,chtype,iccid) {
            // iccid 不是设备号 this.iccid 才是设备号
            this.loadingText='正在切换网络，请稍候...'
            this.isloading=true
            //开关等于50，表示自动模式，卡槽默认使用第一个
            if(checked == 50){
                this.switch_mode = '0',
                this.sim_id = '0'
                if(this.dev_type == 'nx'){
                    this.chooseNetwork(checked)  
                }else{
                    this.chooseNetwork_xy(checked)  
                }
            }else{
                // 判断是否实名
                if(ispick == 2){
                    this.switch_mode = '1',
                    this.sim_id = checked.toString()
                    if(this.dev_type == 'nx'){
                        this.chooseNetwork(checked)  
                    }else{
                        this.chooseNetwork_xy(checked)  
                    }
                    
                }else{
                    // chtype  运营商类型  1移动 2联通 3电信  
                    if(chtype == 2){
                        this.isloading=false
                        Dialog.alert({
                            message: '请先实名',
                        }).then(() => {
                            let url = 'http://zf.iot688.com/iot/iotTishi9.html?iccid='+ iccid;
                            window.location.href= url
                        });
                        
                    }else if(chtype == 3){
                        this.isloading=false
                        Dialog.alert({
                            message: '请先实名',
                        }).then(() => {
                            let url = 'http://iot.yunruiot.com/certif_entry1.html?userCode=r/s9Tc00hjiKcR13MIjZHg==&passWord=9u8VuY1xbez6r6k/BBnLlw==&phone='+ this.phone +'&accessNbr='+ this.iccid
                            window.location.href= url
                        });
                        
                    }else if(chtype == 1 ){
                        this.isloading=false
                        Dialog.alert({
                            message: '请先实名',
                        }).then(() => {
                            // this.iccid = '88852270'
                            let str = 'https://iot.yunruiot.com/?dn=' + this.iccid
                            let new_url = encodeURIComponent(str);
                            let real_url = "http://wx.iot688.com/iot/iotSimfind1?cn=" + this.iccid +'&yurlyb=' + new_url
                            console.log(real_url,'这是移动');
                            window.location.href= real_url
                        });
                    }
                }
            }
        },
        //每次切网时，先调103接口，查看是否连接随身WiFi
        // 调用诺行通用 WEB API 103接口  查询 SIM 卡状态（仅多卡设备支持）
        // 调用诺行通用 WEB API 202接口  切卡（仅多卡设备支持）
        chooseNetwork(checked){

            let data = JSON.stringify({'action':"103"})
            this.$axios({
                url:"http://192.168.1.1/notion_web_api",
                data:data,
                method:"POST",
                timeout: 5000,
                header:{
                    'Content-Type':'application/json'
                }
            }).then(res=>{
                if(res.data.error_code==0){
                    this.isloading=false
                    let data = JSON.stringify({'action':"202",'switch_mode':this.switch_mode,'sim_id':this.sim_id})
                    this.$axios({
                        url:"http://192.168.1.1/notion_web_api",
                        data:data,
                        method:"POST",
                        timeout: 5000,
                        header:{
                            'Content-Type':'application/json'
                        }
                    }).then(res =>{
                        if(res.data.error_code==0){
                            this.isloading=false
                            Dialog.alert({
                                message: '切网成功',
                            }).then(() => {
                            this.checked = parseInt(checked);
                            });
                        }else{
                            this.isloading=false
                            Dialog.alert({
                                message: '切网失败',
                            }).then(() => {
                            });
                        }
                    })
                }else{
                    this.isloading=false
                    Dialog.alert({
                        message: '网络异常，稍后重试',
                    }).then(() => {

                    });
                }
            }).catch( err => {
                this.isloading=false
                Dialog.alert({
                    message: '请先连接您的随身WiFi设备',
                }).then(() => {

                });
            })
        },
        chooseNetwork_xy(checked){
            this.$axios({
                url:"http://192.168.0.1/reqproc/proc_post?goformId=ALK_CJSON_WEBAPI&action=103",
                methods:"GET",
                timeout: 5000,
                header:{
                    'Content-Type':'application/json'
                }
            }).then(res =>{
                if(res.data.error_code==0){
                    this.$axios({
                        url:"http://192.168.0.1/reqproc/proc_post?goformId=ALK_CJSON_WEBAPI&action=202&switch_mode="+this.switch_mode+"&sim_id="+this.sim_id,
                        methods:"GET",
                        // timeout:5000,
                        header:{
                            'Content-Type':'application/json'
                        }
                    }).then(res =>{
                        console.log(res,'切网');
                        if(res.status==200){
                            this.isloading=false
                            Dialog.alert({
                                message: '切网成功',
                            }).then(() => {
                            this.checked = parseInt(checked);
                            });
                        }else{
                            this.isloading=false
                            Dialog.alert({
                                message: '切网失败',
                            }).then(() => {
                            });
                        }
                    })
                }
            })
        },
        //进页面先调103接口，获取当前使用的卡是哪个 当型号是nx的时候
        imeinetfn(){
            this.isloading= true
            let data = JSON.stringify({'action':"103"})
            this.$axios({
                url:"http://192.168.1.1/notion_web_api",
                data:data,
                method:"POST",
                timeout: 5000,
                header:{
                    'Content-Type':'application/json'
                }
            }).then(res=>{
                this.isloading = false
                if(res.data.error_code==0){
                    if(res.data.multi_sim.switch_mode == "0"){
                        this.checked = 50
                    }else{
                        this.checked =res.data.multi_sim.current_sim_id - 0
                    }
                }else{
                    Dialog.alert({
                        message: '网络异常，稍后重试',
                    }).then(() => {

                    });
                }
            }).catch( err => {
                this.isloading = false
                Dialog.alert({
                    message: '请先连接您的随身WiFi设备',
                }).then(() => {

                });
            })
        },
        // 当型号是xy的时候调这个103接口
        imeinetfn_xy(){
            this.isloading= true
            this.$axios({
                url:"http://192.168.0.1/reqproc/proc_post?goformId=ALK_CJSON_WEBAPI&action=103",
                methods:"GET",
                timeout: 5000,
                header:{
                    'Content-Type':'application/json'
                }
            }).then(res =>{
                this.isloading = false
                if( res.data.error_code==0 ){
                    if(res.data.multi_sim.switch_mode == "0"){
                        this.checked = 50
                    }else{
                        this.checked =res.data.multi_sim.current_sim_id - 0
                    }
                }else{
                    Dialog.alert({
                        message: '网络异常，稍后重试',
                    }).then(() => {

                    });
                }
            }).catch(err =>{
                this.isloading = false
                Dialog.alert({
                    message: '请先连接您的随身WiFi设备',
                }).then(() => {

                });
            })
        },
    }
}
</script>

<style lang="less" scoped>
    .changeNetwork{
        overflow: hidden;
        background: #EBEBEB;
        height: 100vh;
        .cardList{
            margin: 10px 15px;
            border-radius: 10px;
            padding: 20px 10px;
            background: #F3F4F6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .card_left{
                display: flex;
                align-items: center;
                img{
                    width: 48px;
                    height: 36px;
                    margin-right: 12px;
                }
                .card_info{
                    .card_name{
                        color: #222222;
                        font-weight: bold;
                        font-size: 16px;
                    }
                    .card_state{
                        font-size: 12px;
                        font-weight: 500;
                        color: #ABB4C1;
                        margin-top: 5px;
                    }
                    .hadcard_state{
                        color: #1FCA80;
                        font-size: 12px;
                        font-weight: 500;
                        margin-top: 5px;
                    }
                }
            }
            .card_right{
                .goName{
                    width: 54px;
                    text-align: center;
                    height: 24px;
                    line-height: 24px;
                    background: #1FCA80;
                    color: #fff;
                    border-radius: 12px;
                }
            }
        }
        .intelligent{
            padding: 20px 10px;
            margin: 10px 15px;
            background-color: #F3F4F6;
            border-radius: 10px;
            border: 1px solid #D4D9E0;
            .intelligent_info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid #D4D9E0;
                .intelligent_left{
                    .intelligent_title{
                        font-size: 16px;
                        color: #222222;
                        font-weight: bold;
                    }
                    .intelligent_tip{
                        color: #666666;
                        font-size: 12px;
                        margin-top: 10px;
                        
                    }
                }
            }
            .hint{
                margin-top: 15px;
                color: #222222;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    display: flex;
    align-items: center;
    .waite{
        margin-left: 10px;
        font-size: 14px;
        color: #fff;

    }
  }
    
</style>